export const socialConfig = {
    disableInstagram: true,
    disableTwitter: true,
    disableFacebook: true,
    disableLinkedIn: true,
    disableOnChallenCard: true,
    hideFacebookOnCommunity: true,
    hideTwitterOnCommunity: true,
    hideInstagramOnCommunity: true,
    hideLinkedInOnCommunity: true
};
