// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./CharterITCW05-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./CharterITCW05-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./CharterITCW05-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./CharterITCW05-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./CharterITCW05-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./CharterITCW05-Black.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"charterITC\";\n    src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: \"charterITC\";\n    src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: \"charterITC\";\n    src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n    font-weight: 800;\n}", "",{"version":3,"sources":["webpack://./src/style/fonts/charterITC/charterITC.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;0DACkD;IAClD,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB;0DAC+C;IAC/C,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB;0DACgD;IAChD,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: \"charterITC\";\n    src:  url('./CharterITCW05-Regular.woff2') format('woff2'),\n    url('./CharterITCW05-Regular.woff') format('woff');\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: \"charterITC\";\n    src:  url('./CharterITCW05-Bold.woff2') format('woff2'),\n    url('./CharterITCW05-Bold.woff') format('woff');\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: \"charterITC\";\n    src:  url('./CharterITCW05-Black.woff2') format('woff2'),\n    url('./CharterITCW05-Black.woff') format('woff');\n    font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
