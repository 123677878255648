import { RegisterConfig } from "@energylab/shared-components/constants/config/registerConfig";
import { UserPrivacyInput } from "@energylab/shared-components/models/graphql/types";
import { registerStructure } from "../registerStructure";

export const registerConfig: RegisterConfig = {
    backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1565255561/pwcffy/ffy-login-5.jpg",
    requireEmailValidation: false,
    disableColorOverlay: true,
    structure: registerStructure,
    formToValues: (values, originalValues) => {
        const originalUser = (originalValues ? originalValues.profile : {}) || {};
        const originalProfile = originalUser.profile;
        const originalPrivacy: Partial<UserPrivacyInput> = originalUser.privacy || {};

        const {
            password,
            companyCode,
            givenName,
            familyName,
            email,
            locale,
            avatar,
            publicDisplayNameType,
            nickname,
            showActivities,
            privacy,
            disclaimer,
            cookyPolicy,
            ...profileValues
        } = values;

        return {
            ...originalValues,
            email: email as string,
            password: password as string,
            companyCode: companyCode as string,
            profile: {
                ...originalUser,
                givenName: givenName as string,
                familyName: familyName as string,
                email: email as string,
                locale: locale as string,
                avatar: avatar as string,
                profile: {
                    ...originalProfile,
                    ...profileValues,
                    hasAcceptedAllPolicies: Boolean(privacy && disclaimer && cookyPolicy)
                },
                privacy: {
                    ...originalPrivacy,
                    publicDisplayNameType: (publicDisplayNameType && "nickname") || "fullname",
                    nickname: values.nickname ? nickname as string : undefined,
                    showActivities: Boolean(showActivities),
                    showAsNewMember: false
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const user = values.profile || {};
        const profile = user.profile || {};
        const privacy: Partial<UserPrivacyInput> = user.privacy || {};

        return {
            terms: profile.terms,
            email: values.email,
            password: values.password,
            passwordConfirm: values.password,
            companyCode: values.companyCode,
            givenName: user.givenName,
            familyName: user.familyName,
            sex: profile.sex,
            birthDate: profile.birthDate,
            nationality: profile.nationality,
            locale: user.locale,
            avatar: user.avatar,
            publicDisplayNameType: privacy.publicDisplayNameType,
            nickname: privacy.nickname,
            showActivities: privacy.showActivities,

            privacy: profile.hasAcceptedAllPolicies,
            disclaimer: profile.hasAcceptedAllPolicies,
            cookyPolicy: profile.hasAcceptedAllPolicies,
            country: profile.country
        };
    }
};
