import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    fullWidth: true,
    navbarItems: [
        {
            id: "dashboard",
            translationKey: "menuItem.dashboard",
            redirectTo: {
                path: "dashboard"
            },
            public: true
        },
        {
            id: "about",
            translationKey: "menuItem.about",
            redirectTo: { path: "about" }
        },
        {
            id: "special-olympics",
            translationKey: "menuItem.specialOlympics",
            redirectTo: { path: "special-olympics" }
        },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        // HEROS/AMBASSADORS
        // {
        //     id: "ambassadors",
        //     translationKey: "menuItem.ambassadors",
        //     redirectTo: {
        //         path: "ambassadors"
        //     }
        // },
        // EVENTS
        // {
        //     id: "events",
        //     translationKey: "menuItem.events",
        //     redirectTo: { path: "events" }
        // },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "faq",
            translationKey: "menuItem.faq",
            redirectTo: { path: "faq" }
        },
        {
            id: "contact",
            translationKey: "menuItem.contact",
            redirectTo: { path: "contact" }
        },

    ]
};