export interface Nationality {
    code: string;
    name: string;
}

export const unsortedNationalities = [
    { code: "AFG", name: "Afghan" },
    { code: "ALB", name: "Albanian" },
    { code: "ATA", name: "Antarctique" },
    { code: "DZA", name: "Algerian" },
    { code: "ASM", name: "Samoa Américaines" },
    { code: "AND", name: "Andorran" },
    { code: "AGO", name: "Angolan" },
    { code: "ATG", name: "Antiguan" },
    { code: "AZE", name: "Azerbaijani" },
    { code: "ARG", name: "Argentinian or Argentine" },
    { code: "AUS", name: "Australian" },
    { code: "AUT", name: "Austrian" },
    { code: "BHS", name: "Bahamian" },
    { code: "BHR", name: "Bahraini" },
    { code: "BGD", name: "Bangladeshi" },
    { code: "ARM", name: "Armenian" },
    { code: "BRB", name: "Barbadian or Bajan" },
    { code: "BEL", name: "Belgian" },
    { code: "BMU", name: "Bermudian" },
    { code: "BTN", name: "Bhutanese" },
    { code: "BOL", name: "Bolivian" },
    { code: "BIH", name: "Bosnian, Herzegovinian" },
    { code: "BWA", name: "Motswana" },
    { code: "BVT", name: "Bouvet, Île" },
    { code: "BRA", name: "Brazilian" },
    { code: "BLZ", name: "Belizean" },
    { code: "SLB", name: "Solomon Islander" },
    { code: "BRN", name: "Bruneian" },
    { code: "BGR", name: "Bulgarian" },
    { code: "MMR", name: "Myanmari" },
    { code: "BDI", name: "Burundian" },
    { code: "BLR", name: "Belarusian" },
    { code: "KHM", name: "Cambodian" },
    { code: "CMR", name: "Cameroonian" },
    { code: "CAN", name: "Canadian" },
    { code: "CPV", name: "Cape Verdean" },
    { code: "CYM", name: "Caymanian" },
    { code: "CAF", name: "Central African" },
    { code: "LKA", name: "Sri Lankan" },
    { code: "TCD", name: "Chadian" },
    { code: "CHL", name: "Chilean" },
    { code: "CHN", name: "Chinese" },
    { code: "TWN", name: "Taiwanese" },
    { code: "CXR", name: "Christmas Island" },
    { code: "CCK", name: "Cocos Islander" },
    { code: "COL", name: "Colombian" },
    { code: "COM", name: "Comoran" },
    { code: "MYT", name: "Mahoran" },
    { code: "COG", name: "Congolese" },
    { code: "COK", name: "Cook Islander" },
    { code: "CRI", name: "Costa Rican" },
    { code: "HRV", name: "Croatian" },
    { code: "CUB", name: "Cuban" },
    { code: "CYP", name: "Cypriot" },
    { code: "CZE", name: "Czech" },
    { code: "BEN", name: "Beninese or Beninois" },
    { code: "DNK", name: "Danish" },
    { code: "DMA", name: "Dominican" },
    { code: "DOM", name: "Dominicaine" },
    { code: "ECU", name: "Ecuadorian" },
    { code: "SLV", name: "Salvadoran" },
    { code: "GNQ", name: "Equatorial Guinean" },
    { code: "ETH", name: "Ethiopian" },
    { code: "ERI", name: "Eritrean" },
    { code: "EST", name: "Estonian" },
    { code: "FRO", name: "Féroé, Îles" },
    { code: "FLK", name: "Falkland Island" },
    { code: "SGS", name: "Géorgie Du Sud" },
    { code: "FJI", name: "Fijian" },
    { code: "FIN", name: "Finnish" },
    { code: "FRA", name: "French" },
    { code: "GUF", name: "Guyanaise" },
    { code: "PYF", name: "Polynésienne" },
    { code: "DJI", name: "Djibouti" },
    { code: "GAB", name: "Gabonese" },
    { code: "GEO", name: "Georgian" },
    { code: "GMB", name: "Gambian" },
    { code: "PSE", name: "Palestinien" },
    { code: "DEU", name: "German" },
    { code: "GHA", name: "Ghanaian" },
    { code: "GIB", name: "Gibraltar" },
    { code: "KIR", name: "I-Kiribati" },
    { code: "GRC", name: "Greek" },
    { code: "GRL", name: "Greenlandic" },
    { code: "GRD", name: "Grenadian" },
    { code: "GLP", name: "Guadeloupe" },
    { code: "GUM", name: "Guamanian" },
    { code: "GTM", name: "Guatemalan" },
    { code: "GIN", name: "Guinean" },
    { code: "GUY", name: "Guyanese" },
    { code: "HTI", name: "Haitian" },
    { code: "HMD", name: "Heard" },
    { code: "VAT", name: "Saint-Siège" },
    { code: "HND", name: "Honduran" },
    { code: "HKG", name: "Hong-Kong" },
    { code: "HUN", name: "Hungarian" },
    { code: "ISL", name: "Icelandic" },
    { code: "IND", name: "Indian" },
    { code: "IDN", name: "Indonesian" },
    { code: "IRN", name: "Iranian" },
    { code: "IRQ", name: "Iraqi" },
    { code: "IRL", name: "Irish" },
    { code: "ISR", name: "Israeli" },
    { code: "ITA", name: "Italian" },
    { code: "CIV", name: "Ivorian" },
    { code: "JAM", name: "Jamaican" },
    { code: "JPN", name: "Japanese" },
    { code: "KAZ", name: "Kazakhstani" },
    { code: "JOR", name: "Jordanian" },
    { code: "KEN", name: "Kenyan" },
    { code: "KOR", name: "Korean" },
    { code: "KWT", name: "Kuwaiti" },
    { code: "KGZ", name: "Kyrgyzstani" },
    { code: "LAO", name: "Laotian or Lao" },
    { code: "LBN", name: "Lebanese" },
    { code: "LSO", name: "Basotho" },
    { code: "LVA", name: "Latvian" },
    { code: "LBR", name: "Liberian" },
    { code: "LBY", name: "Libyan" },
    { code: "LIE", name: "Liechtenstein" },
    { code: "LTU", name: "Lithuanian" },
    { code: "LUX", name: "Luxembourg" },
    { code: "MAC", name: "Macao" },
    { code: "MDG", name: "Malagasy" },
    { code: "MWI", name: "Malawian" },
    { code: "MYS", name: "Malaysian" },
    { code: "MDV", name: "Maldivian" },
    { code: "MLI", name: "Malian" },
    { code: "MLT", name: "Maltese" },
    { code: "MTQ", name: "Martiniquais" },
    { code: "MRT", name: "Mauritanian" },
    { code: "MUS", name: "Mauritian" },
    { code: "MEX", name: "Mexican" },
    { code: "MCO", name: "Monegasque" },
    { code: "MNG", name: "Mongolian" },
    { code: "MDA", name: "Moldovan" },
    { code: "MSR", name: "Montserratian" },
    { code: "MAR", name: "Moroccan" },
    { code: "MOZ", name: "Mozambican" },
    { code: "OMN", name: "Omani" },
    { code: "NAM", name: "Namibian" },
    { code: "NRU", name: "Nauru" },
    { code: "NPL", name: "Nepali" },
    { code: "NLD", name: "Dutch" },
    { code: "ANT", name: "Dutch Antillean" },
    { code: "ABW", name: "Aruba" },
    { code: "NCL", name: "New Caledonian" },
    { code: "VUT", name: "Ni-Vanuatu" },
    { code: "NZL", name: "New Zealand" },
    { code: "NIC", name: "Nicaraguan" },
    { code: "NER", name: "Nigerien" },
    { code: "NGA", name: "Nigerian" },
    { code: "NIU", name: "Niué" },
    { code: "NFK", name: "Norfolk, Île" },
    { code: "NOR", name: "Norwegian" },
    { code: "MNP", name: "Mariannes Du Nord, Îles" },
    { code: "FSM", name: "Micronesian" },
    { code: "MHL", name: "Marshallese" },
    { code: "PLW", name: "Palaos" },
    { code: "PAK", name: "Pakistani" },
    { code: "PAN", name: "Panamanian" },
    { code: "PNG", name: "Papuan" },
    { code: "PRY", name: "Paraguayan" },
    { code: "PER", name: "Peruvian" },
    { code: "PHL", name: "Philippine" },
    { code: "PCN", name: "Pitcairn Islander" },
    { code: "POL", name: "Polish" },
    { code: "PRT", name: "Portuguese" },
    { code: "GNB", name: "Guinéenne" },
    { code: "TLS", name: "Timor-Leste" },
    { code: "PRI", name: "Puerto Rican" },
    { code: "QAT", name: "Qatari" },
    { code: "REU", name: "Réunionese" },
    { code: "ROU", name: "Romanian" },
    { code: "RUS", name: "Russian" },
    { code: "RWA", name: "Rwandan" },
    { code: "SHN", name: "Saint Helenian" },
    { code: "KNA", name: "Kittitian" },
    { code: "AIA", name: "Anguillan" },
    { code: "LCA", name: "Saint Lucian" },
    { code: "VCT", name: "Grenadines" },
    { code: "SMR", name: "Sammarinese" },
    { code: "STP", name: "São Toméan" },
    { code: "SAU", name: "Saudi" },
    { code: "SEN", name: "Senegalese" },
    { code: "SYC", name: "Seychellois" },
    { code: "SLE", name: "Sierra Leonean" },
    { code: "SGP", name: "Singapore" },
    { code: "SVK", name: "Slovak" },
    { code: "VNM", name: "Vietnamese" },
    { code: "SVN", name: "Slovenian" },
    { code: "SOM", name: "Somali" },
    { code: "ZAF", name: "South African" },
    { code: "ZWE", name: "Zimbabwean" },
    { code: "ESP", name: "Spanish" },
    { code: "ESH", name: "Saharienne" },
    { code: "SDN", name: "Sudanese" },
    { code: "SUR", name: "Surinamese" },
    { code: "SJM", name: "Svalbard Et Île Jan Mayen" },
    { code: "SWZ", name: "Swazi" },
    { code: "SWE", name: "Swedish" },
    { code: "CHE", name: "Swiss" },
    { code: "SYR", name: "Syrian" },
    { code: "TJK", name: "Tajikistani" },
    { code: "THA", name: "Thai" },
    { code: "TGO", name: "Togolese" },
    { code: "TKL", name: "Tokelau" },
    { code: "TON", name: "Tongan" },
    { code: "TTO", name: "Trinidadian" },
    { code: "ARE", name: "Emirati" },
    { code: "TUN", name: "Tunisian" },
    { code: "TUR", name: "Turkish" },
    { code: "TKM", name: "Turkmen" },
    { code: "TCA", name: "Turks Et Caïques, Îles" },
    { code: "TUV", name: "Tuvaluan" },
    { code: "UGA", name: "Ugandan" },
    { code: "UKR", name: "Ukrainian" },
    { code: "MKD", name: "Macedonian" },
    { code: "EGY", name: "Egyptian" },
    { code: "GBR", name: "British" },
    { code: "TZA", name: "Tanzanian" },
    { code: "USA", name: "American" },
    { code: "VIR", name: "Virgin Islander" },
    { code: "BFA", name: "Burkinabe" },
    { code: "URY", name: "Uruguayan" },
    { code: "UZB", name: "Uzbekistani" },
    { code: "VEN", name: "Venezuelan" },
    { code: "WLF", name: "Wallisian" },
    { code: "WSM", name: "Samoan" },
    { code: "YEM", name: "Yemeni" },
    { code: "SCG", name: "Serbian" },
    { code: "ZMB", name: "Zambian" }
];

export const nationalities = unsortedNationalities.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
});
