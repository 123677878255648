/* eslint-disable react/prop-types */
import { RedirectType } from "@energylab/shared-components/routes/routes";
import { lazy } from "react";

const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));

export const ROUTES = [
    {
        element: <ContentContainer type="specialOlympics" />,
        path: "special-olympics"
    }
];

export const REDIRECTS: RedirectType[] = [];
