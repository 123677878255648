/* eslint-disable global-require */
export const providers = [
    "strava",
    "garmin",
    "fitbit",
    "runkeeper",
    "start2run",
    "pacer",
    "connect"
];
