import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { dashboardPlacesConfig } from "@energylab/shared-components/constants/config/dashboardPlacesConfig";
import { articleTagsConfig } from "./constants/config/articleTagsConfig";
import { auth } from "./constants/config/authConfig";
import { cardConfig } from "./constants/config/cardConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { dailyOverviewConfig } from "./constants/config/dailyOverviewConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { headerConfig } from "./constants/config/headerConfig";
import { leaderboardConfig } from "./constants/config/leaderboardConfig";
import { socialShareConfig } from "./constants/config/socialShareConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { logoutConfig } from "./constants/config/logoutConfig";
import { nationalities } from "./constants/config/nationalities";
import { navbarConfig } from "./constants/config/navbarConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { providers } from "./constants/config/providers";
import { registerConfig } from "./constants/config/registerConfig";
import { resetPasswordConfig } from "./constants/config/resetPasswordConfig";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { webshopConfig } from "./constants/config/webshopConfig";
import { profileConfig } from "./constants/config/profileConfig";

export const config: ConfigReducerState = {
    registerConfig,
    profileConfig,
    hideSocialLogin: true,
    navbarConfig,
    headerConfig,
    articleTagsConfig,
    socialConfig,
    ambassadorImage: "",
    auth,
    commentsConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: "https://res.cloudinary.com/energylab/image/upload/v1566387787/pwcffy/PwC_Fit_for_You-01_1.svg",
    companyName: "PwC",
    dashboardTopPadding: "50px",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    footerConfig,
    coinsConfig,
    likesConfig,
    webshopConfig,
    notificationsConfig,
    providers,
    dashboardPlacesConfig: {
        ...dashboardPlacesConfig,
        homePage: ["dashboard_pwc"]
    },
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    nationalities,
    selectedLanguage: getBrowserLocale(routeConfig.defaultLocale, routeConfig.locales),
    seo,
    uploadPreset: "pwcffy",
    newUsersConfig,
    GA_ENABLED: true,
    dashboardTimelineTitle: "dashboardTimelineTitle",
    dailyOverviewConfig,
    cardConfig,
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    resetPasswordConfig,
    logoutConfig,
    galleryConfig,
    leaderboardConfig,
    whiteListSignUpConfig,
    socialShareConfig,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "nationality"
    ],
};
