import { Theme } from "@energylab/shared-components/utils/styledComponents";
import { generateNewTheme } from "@energylab/shared-components/style/theme";

export const theme: Theme = generateNewTheme({
    colors: {
        primary: "#e0301e",
        primaryHover: "#e0301e",
        secondary: "#e0301e",
        black: "#000000",
        white: "#FFFFFF",
        darkGrey: "#2D2D2D",
        mediumGrey: "#464646",
        subGrey: "#7D7D7D",
        lightGrey: "#EEEEEE",
        statisticsBannerColor: "#F1EDEB",
        statisticsBannerTextColor: "#414141",
        error: "#e0301e",
        warning: "#FFB800",
        success: "#175c2c",
        background: "#EEEEEE"
    },
    activityColors: {
        running: "#e0301e",
        cycling: "#6E6E6E",
        other: "#4565A0",
        swimming: "#7caad6",
        walking: "#96b439"
    },
    breakpoints: {
        header: "1024px",
        footer: "800px",
        innerContainer: "767px",
        profileHeader: "980px",
        profileInfo: "1024px"
    },
    fonts: {
        primary: "\"helveticaNeue\", sans-serif",
        secondary: "'charterITC', sans-serif",
        h1: "'charterITC', sans-serif",
        widgetTitle: "\"helveticaNeue\", sans-serif"
    },
    header: {
        defaultHeight: 80,
        mobileHeight: 80
    },
    footer: {
        defaultHeight: 60,
        mobileHeight: 160,
        background: "#7D7D7D",
        textColor: "#FFFFFF"
    },
    widgets: {
        margin: 40,
        backgroundOpacity: 0.5
    },
    innerContainer: {
        maxWidth: 1600,
        padding: 80
    },
    shadows: {
        bigShadow: "0 0px 0px rgba(0,0,0,0.16), 0 0px 0px rgba(0,0,0,0.23)",
        smallShadow: "0 0px 0px rgba(0,0,0,0.06), 0 0px 0px rgba(0,0,0,0.10)",
        lineShadow: "0 0 0 1px rgba(0,0,0,0.08)",
        inputShadow: "none",
        insetShadow: "inset 0 1px 3px rgba(0, 0, 0, .25)",
        cardShadow: "0 0px 0px 0px rgba(0,0,0,.1)",
        cardHoverShadow: "0 0px 0px 0 rgba(0, 0, 0, .1)",
        tagShadow: "none"
    },
    borders: {
        inputBorder: "1px solid #C2C2C2"
    },
    borderRadius: {
        bigBorderRadius: "0px",
        smallBorderRadius: "0px",
        tagBorderRadius: "30px"
    },
    navbarStyle: {
        height: 80,
        backgroundColor: "#2d2d2d",
        textColor: "#888",
        activeTextColor: "#FFFFFF",
        borderColor: "transparent",
        font: "\"helveticaNeue\", sans-serif",
        activeBorderHeight: 0,
        mobileBorderBottom: "1px solid #F3F3F3"
    },
    buttonStyle: {
        borderRadius: "0px"
    },
    logo: {
        maxHeight: 52,
        mobileMaxHeight: 40,
        minWidth: 100,
        maxWidth: 200
    },
    userNav: {
        fontSize: 16,
        mobileFontSize: 14
    },
    text: {
        maxWidth: "100%"
    },
    tabs: {
        borderSize: 4
    },
    pageTitle: {
        color: "#2D2D2D"
    },
    fixedTagStyles: {
        border: "1px solid #7d7d7d",
        color: "#2D2D2D",
        background: "transparent",
        activeBorder: "1px solid #7d7d7d",
        activeColor: "#FFFFFF",
        activeBackground: "#7D7D7D"
    },
    challengeCardBottom: {
        background: "#FAFAFA",
    }
});

export const ANTD_THEME = {
    primaryColor: "#e0301e",
    //     'error-color': "#CF4948",
    //     'layout-sider-background': "#282828",
    //     'layout-header-background': "#FFFFFF",
    //     'layout-header-height': "55px",
    //     'layout-header-padding':"0 39px",
    //     'menu-dark-color': "#BABABA",
    //     'menu-dark-bg': '#282828',
    //     'menu-dark-arrow-color': '#FFFFFF',
    //     'menu-dark-submenu-bg': '#101010',
    //     'menu-dark-highlight-color': '#FDFEFC',
    //     'menu-dark-item-active-bg': primaryColor
    // }
}