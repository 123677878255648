import { getRoutes } from "@energylab/shared-components/routes";
import * as environment from "environment";
import { ROUTES } from "../../routes";

const locales = environment.debug ? ["en", "nl"] : ["en"];
const defaultLocale = "en";

export const routeConfig = {
    locales,
    defaultLocale,
    routes: {
        private: getRoutes(ROUTES)
    },
    redirects: [],
    hideNavigationWhenLoggedOut: true
};
