import { SeoConfig } from "@energylab/shared-components/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "PwC – Fit for You",
    title: {
        nl: "PwC – Fit for You",
        fr: "PwC – Fit for You",
        en: "PwC – Fit for You"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: "",
    seoTitlePlacement: "right"
};
